<template>
  <div class="bb-alert-container">
    <div
      class="bb-alert success"
      v-for="(message, index) in successMessages"
      :key="index"
      :ref="'success-' + index"
    >
      <button @click="hideSuccessMessage(index)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-check-circle"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
          <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
      </button>
      <span>{{ message }}</span>
    </div>
    <div
      class="bb-alert danger"
      v-for="(message, index) in errorMessages"
      :key="index"
      :ref="'error-' + index"
    >
      <button @click="hideErrorMessage(index)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </button>
      <span
        v-if="
          message === 'The given data was invalid.' 
          && activeLanguage() !== 'en'
        ">
        Došlo je do greške
        </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "BBAlert",
  methods: {
    /**
     * Hide success message
     * @param {Number} index
     * @return {void}
     */
    hideSuccessMessage(index) {
      this.$refs["success-" + index][0].style = "display: none;";
    },

    /**
     * Hide error message
     * @param {Number} index
     * @return {void}
     */
    hideErrorMessage(index) {
      this.$refs["error-" + index][0].style = "display: none;";
    },

    /**
     * Get active language
     * @return {String}
     */
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
  },
  computed: {
    ...mapGetters(["successMessages", "errorMessages"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/bb-styles/scoped/alert.scss";
</style>
